<template>
    <div class="wine" :style="{ backgroundImage: `url(${BgImage})` }" style="background-size: auto; background-position: center">
        <section>
            <div class="container main-container">
                <!-- section one -->
                <section class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-md-12 header-col wow fadeInDown">
                                <h1 class="header-title">6 Wineries</h1>
                                <p class="header-sub">5 Destinations of Origins</p>
                            </div>
                        </div>
                        <div class="spacing"></div>
                        <div class="row">
                            <div class="col-md-4 col-lg-6 wow fadeInLeft">
                                <div class="row">
                                    <div class="col-12 group-logo-col">
                                        <img class="img-fluid" src="../assets/images/grupo_faustino.png">
                                    </div>
                                    <div class="col-12">
                                        <p>From Grupo Faustino we understand that the care of our vineyards is key to elaborate high quality wines. Being one of the largest owners of vineyards enable us to guarantee the best care to the land, assure the traceability of our productions and identify and allocate harvesting areas for each wine.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8 col-lg-6 wow fadeInRight">
                                <img class="img-fluid" src="../assets/images/map.png">
                            </div>
                        </div>
                    </div>
                </section>
                <div class="spacing"></div>
                <section class="row">
                    <div class="col-12 header-col wow fadeInDown">
                        <div class="header-sub">The Story Of</div>
                        <h1 style="color: #000">GRUPO WINERIES</h1>
                    </div>
                    <div class="col-12 wow fadeInDown" data-wow-delay="0.3s">
                        <div class="row">
                            <div class="col-12 story-col">
                                <div class="row">
                                    <div class="col-md-12 col-lg-6 col-list col-text">
                                        <p>We are deeply committed with the environment and we have carbon footprint certification from AENOR for eight wines from six of our wineries.</p>
                                        <p>All our wineries have their waste managed and follow effective emission reduction policies. The Group owns 16 hectares of vineyards for the production of organic wine, with an outstanding reception in the European market.</p>
                                        <p>All the wineries have ISO 9001 quality certification. The Campillo, Portia and Marqués de Vitoria Portia wineries have ISO 14001 environmental management certification. Bodegas Faustino and Bodegas Campillo have BRC (British Retail Consortium) certification.</p>
                                    </div>
                                    <!-- Faustino -->
                                    <div class="col-md-4 col-lg-3 col-list">
                                        <img class="img-fluid image" src="../assets/images/locations/winery.bodegas.faustino.of.png">
                                        <a target="_blank" href="http://www.bodegasfaustino.com/en/">
                                            <div class="overlay" style="background-color: #BD9E57">
                                                <div class="title">Bodegas Faustino</div>
                                                <div class="sub">International renown</div>
                                                <div class="desc">Located in Oyon, Rioja Alavesa, Bodegas Faustino enjoys a well-deserved international reputation after more than 150 years making and ageing premium wines.</div>
                                            </div>
                                        </a>
                                    </div>
                                    <!-- Campillo -->
                                    <div class="col-md-4 col-lg-3 col-list">
                                        <img class="img-fluid image" src="../assets/images/locations/winery.bodegas.campìllo.of.png">
                                        <a target="_blank" href="http://www.bodegascampillo.com/en/">
                                            <div class="overlay" style="background-color: #B5B5B5">
                                                <div class="title">Bodegas Campillo</div>
                                                <div class="sub">A winery that opens to a great vineyard</div>
                                                <div class="desc">A winery of outstanding architecture built in the style of a Bordeaux château in the middle of a vineyard at the foot of the Sierra de Cantabria. Campillo is the best example of the union of architecture, vineyard and wine.</div>
                                            </div>
                                        </a>
                                    </div>
                                    <!-- Portia -->
                                    <div class="col-md-4 col-lg-3 col-list">
                                        <img class="img-fluid image" src="../assets/images/locations/winery.bodegas.portia.of.png">
                                        <a target="_blank" href="http://www.bodegasportia.com/en/">
                                            <div class="overlay" style="background-color: #BD9E57">
                                                <div class="title">BODEGAS PORTIA</div>
                                                <div class="sub">“A three-pointed star in the heart of Ribera del Duero”. Sir Norman Foster</div>
                                                <div class="desc">A unique project by the famed British architect Sir Norman Foster. Portia was the culmination of an achievement of perfect harmony between aesthetics and functionality.</div>
                                            </div>
                                        </a>
                                    </div>
                                    <!-- Valcarlos -->
                                    <div class="col-md-4 col-lg-3 col-list">
                                        <img class="img-fluid image" src="../assets/images/locations/winery.bodegas.valcarlos.of.png">
                                        <a target="_blank" href="http://www.bodegasvalcarlos.com/es/">
                                            <div class="overlay" style="background-color: #B5B5B5">
                                                <div class="title">BODEGAS VALCARLOS</div>
                                                <div class="sub">On the St James’ Way, in the heart of Navarre.</div>
                                                <div class="desc">With vineyards very close to the winery, Valcarlos stands out for the harmony of its exterior design and the beauty of its interior, with its colourful wine racks and superb barrel hall.</div>
                                            </div>
                                        </a>
                                    </div>
                                    <!-- Marques -->
                                    <div class="col-md-4 col-lg-3 col-list">
                                        <img class="img-fluid image" src="../assets/images/locations/winery.bodegas.marques.on.png">
                                        <a target="_blank" href="http://www.marquesdevitoria.com/es/">
                                            <div class="overlay" style="background-color: #BD9E57">
                                                <div class="title">BODEGAS MARQUÉS DE VITORIA</div>
                                                <div class="sub">Innovation and Environmental Commitment.</div>
                                                <div class="desc">A winery with innovative winemaking processes, with a modern, organic style, like Ecco, which comes from an organic vineyard.</div>
                                            </div>
                                        </a>
                                    </div>
                                    <!-- Leganza -->
                                    <div class="col-md-4 col-lg-3 col-list">
                                        <img class="img-fluid image" src="../assets/images/locations/winery.bodegas.leganza.on.png">
                                        <a target="_blank" href="http://www.bodegasleganza.com/es/">
                                            <div class="overlay" style="background-color: #B5B5B5">
                                                <div class="title">BODEGAS LEGANZA</div>
                                                <div class="sub">A taste of La Mancha.</div>
                                                <div class="desc">At the heart of the Don Quixote Route, the winery owns the spectacular Finca los Trenzones, where a vine selection process is carried out to give its wines a special character.</div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="spacing"></div>
                <div class="spacing"></div>
                <section class="row do-row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-md-3 wow fadeInDown" data-wow-delay="0.1s">
                                <img class="img-fluid" src="../assets/images/commit/DO-rioja.png">
                                <p class="do-title">D.O.CA. RIOJA</p>
                                <p class="do-has">660 Has</p>
                                <p class="do-desc">Of the grape varieties Tempranillo, Garnacha, Malvasía, Chardonnay, Graciano, Tempranillo Peludo, Verdejo, Viura, Sauvignon Blanc and Mazuelo.</p>
                            </div>

                            <div class="col-md-3 wow fadeInDown" data-wow-delay="0.2s">
                                <img class="img-fluid" src="../assets/images/commit/DO-ribera.png">
                                <p class="do-title">D.O. Ribera del Duero</p>
                                <p class="do-has">222 Has</p>
                                <p class="do-desc">Of the grape variety Tempranillo.</p>
                            </div>

                            <div class="col-md-3 wow fadeInDown" data-wow-delay="0.3s">
                                <img class="img-fluid" src="../assets/images/commit/DO-navarra.png">
                                <p class="do-title">D.O. Navarra</p>
                                <p class="do-has">83 Has</p>
                                <p class="do-desc">Of the grape varieties Tempranillo, Merlot, Chardonnay, Cabernet Sauvignon and Viura.</p>
                            </div>

                            <div class="col-md-3 wow fadeInDown" data-wow-delay="0.4s">
                                <img class="img-fluid" src="../assets/images/commit/DO-mancha.png">
                                <p class="do-title">D.O. MANCHA</p>
                                <p class="do-has">660 Has</p>
                                <p class="do-desc">Of the grape varieties Tempranillo, Syrah, Graciano, Verdejo, Sauvignon Blanc, Garnacha, Cabernet Sauvignon, Merlot and Viura.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="spacing"></div>
                <div class="spacing d-none d-md-block d-lg-none"></div>
            </div>
        </section>
    </div>
</template>

<script>
    import BgImage from '../assets/images/wine-bg.png';
    import BgImage2 from '../assets/images/bg-wine-2.png';
    import {WOW} from "wowjs";

    export default {
        name: "Wine",
        components: {

        },
        data () {
            return {
                BgImage,
                BgImage2
            }
        },
        mounted() {
            const wow = new WOW();

            wow.init();
        },
        methods: {

        }
    }
</script>

<style scoped>

</style>